.main-page {
  width: 100%;
}
.main-page header {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  z-index: 2;
}
@media (max-width: 768px) {
  .main-page header {
    padding: 5px;
  }
}
.main-page header .header-content {
  width: 80%;
  margin: auto;
  padding: 10px 0 30px 0;
  text-align: right;
}
@media (max-width: 768px) {
  .main-page header .header-content {
    width: 90%;
  }
}
.main-page header .header-content .main-logo {
  position: absolute;
  width: 200px;
}
@media (max-width: 768px) {
  .main-page header .header-content .main-logo {
    width: 150px;
  }
}
@media (max-width: 400px) {
  .main-page header .header-content .main-logo {
    width: 100px;
  }
}
.main-page header .header-content .main-logo img {
  width: 100%;
}
.main-page header .header-content nav {
  display: inline-block;
  width: 70%;
  right: 0;
  text-align: right;
}
@media (max-width: 768px) {
  .main-page header .header-content nav {
    width: 60%;
  }
}
.main-page header .header-content nav .buttons {
  display: inline-block;
}
.main-page header .header-content nav .buttons .nav-item {
  display: inline-block;
  cursor: pointer;
  font-size: 0.9em;
  color: #164B75;
  height: 2em;
  padding: 0 5px;
  line-height: 2;
  margin: 5px 0 5px 15px;
  border-radius: 9px;
  width: 6em;
  text-align: center;
  background: linear-gradient(to top, #a0bddf, #a0bddf 50%, #E5E5E5 100%);
}
@media (max-width: 768px) {
  .main-page header .header-content nav .buttons .nav-item {
    font-size: 0.8em;
  }
}
.main-page header .header-content nav .user-login {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  margin-left: 20px;
  color: #164B75;
}
.main-page header .header-content nav .user-login .avatar {
  display: inline-flex;
  width: 2em;
  height: 2em;
  overflow: hidden;
  border-radius: 50%;
}
.main-page header .header-content nav .user-login .avatar a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.main-page header .header-content nav .user-login .avatar img {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.main-page header .header-content nav .user-login .name {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.8em;
  max-width: 150px;
  height: 1.5em;
  margin-left: 5px;
}
@media (max-width: 768px) {
  .main-page header .header-content nav .user-login .name {
    display: none;
  }
}
.main-page header .header-content nav .user-login .button {
  display: inline-flex;
  margin-left: 5px;
  cursor: pointer;
  font-size: 2em;
}
.main-page header .header-content nav .user-login .button a {
  color: #164B75;
}
.main-page .slide {
  overflow: hidden;
  margin: 50px 0;
}
@media (max-width: 768px) {
  .main-page .slide {
    margin: 20px 0;
  }
}
.main-page .slide .slide-img {
  width: 80%;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  margin: auto;
}
@media (max-width: 768px) {
  .main-page .slide .slide-img {
    border-radius: 0;
    width: 100%;
  }
}
.main-page .slide .slide-img img {
  width: 100%;
  margin: auto;
}
.main-page .slide .slide-text {
  position: absolute;
  color: #164B75;
  background-color: #E5E5E5;
  height: 45%;
  width: 30%;
  border-radius: 16px;
  padding: 15px;
  bottom: -20%;
  right: 15%;
  font-size: 1.2em;
  line-height: 1;
}
@media (max-width: 992px) {
  .main-page .slide .slide-text {
    font-size: 0.9em;
  }
}
@media (max-width: 768px) {
  .main-page .slide .slide-text {
    font-size: 0.85em;
    width: 45%;
    height: 55%;
    right: 3%;
  }
}
@media (max-width: 400px) {
  .main-page .slide .slide-text {
    font-size: 0.6em;
    width: 45%;
    height: 60%;
    right: 3%;
  }
}
.main-page .slide.top .slide-text {
  top: -20%;
  padding-top: 12%;
}
@media (max-width: 992px) {
  .main-page .slide.top .slide-text {
    padding-top: 12.5%;
  }
}
@media (max-width: 768px) {
  .main-page .slide.top .slide-text {
    padding-top: 17%;
  }
}
.main-page .slide.left .slide-text {
  left: 15%;
}
@media (max-width: 768px) {
  .main-page .slide.left .slide-text {
    left: 5%;
  }
}
.main-page .intro {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;
  border-top: 2px solid #164B75;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .main-page .intro {
    flex-direction: column;
    width: 90%;
    margin-top: 15px;
    padding-top: 15px;
  }
}
.main-page .intro h1 {
  width: 100%;
  color: #164B75;
}
.main-page .intro div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-page .intro .text {
  display: inline-block;
  color: #164B75;
  text-align: justify;
  width: 70%;
}
@media (max-width: 768px) {
  .main-page .intro .text {
    font-size: 0.8em;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .main-page .intro .text {
    text-align: left;
  }
}
.main-page .intro .img {
  width: 20%;
  text-align: right;
}
@media (max-width: 992px) {
  .main-page .intro .img {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .main-page .intro .img {
    width: 30%;
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  .main-page .intro .img {
    width: 40%;
    margin-top: 20px;
  }
}
.main-page .intro .img img {
  border-radius: 15px;
  width: 100%;
}
.main-page .footer {
  text-align: center;
  color: #164B75;
  margin: 100px 0 20px;
  border-bottom: 2px solid #164B75;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.main-page .footer a {
  margin-left: 20px;
}
.main-page .footer img {
  width: 50px;
}

.user-contacts-component {
  display: flex;
  align-items: center;
}
.user-contacts-component input {
  margin-left: 10px !important;
}
.user-contacts-component img {
  width: 1.2em;
  cursor: pointer;
  margin: 5px;
  opacity: 0.5;
}
.user-contacts-component img.active {
  width: 1.4em;
  border: 2px solid white;
  border-radius: 50%;
  opacity: 1;
}

.change-password-component {
  margin-bottom: 20px;
}
.change-password-component input {
  margin: 0 0 5px 0 !important;
}

.user-tools {
  width: 60%;
  background-color: #3c709b;
  border-radius: 9px;
  margin: auto;
  margin-top: 5%;
  padding: 3%;
  color: #E5E5E5;
}
@media (max-width: 767px) {
  .user-tools {
    width: 95%;
  }
}
.user-tools .form-header {
  font-weight: bold;
}
.user-tools .form-header h3 {
  font-size: 1.1em;
  font-weight: bold;
}
.user-tools .form-body {
  color: #E5E5E5;
}
.user-tools .form-body select {
  width: 80%;
  margin-bottom: 10px !important;
}
@media (max-width: 992px) {
  .user-tools .form-body select {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .user-tools .form-body select {
    width: 100%;
  }
}
.user-tools .form-body input {
  margin: 10px 0;
  width: 80%;
  padding: 0 0 0 10px;
  border-radius: 3px;
  border: none;
}
.user-tools .form-body input.small {
  width: 50%;
}
.user-tools .form-body input.number {
  width: 6em;
}
@media (max-width: 992px) {
  .user-tools .form-body input {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .user-tools .form-body input {
    width: 100%;
  }
}
.user-tools .form-body button {
  padding: 3px;
  margin: 10px;
  border-radius: 3px;
}
.user-tools .form-body textarea {
  width: 80%;
  border-radius: 7px;
  min-height: 3em;
  padding: 5px;
  margin: 10px 0;
}
@media (max-width: 992px) {
  .user-tools .form-body textarea {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .user-tools .form-body textarea {
    width: 100%;
  }
}
.user-tools .form-body .user-img-upload {
  margin-bottom: 35px;
  font-size: 1.1em;
  width: 80%;
}
@media (max-width: 992px) {
  .user-tools .form-body .user-img-upload {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .user-tools .form-body .user-img-upload {
    width: 100%;
  }
}
.user-tools .form-body .user-img-upload .avatar-upload-img {
  display: flex;
  align-items: center;
}
.user-tools .form-body .user-img-upload .avatar-upload-img .avatar {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.user-tools .form-body .user-img-upload .avatar-upload-img .avatar div {
  display: flex;
  align-items: center;
}
.user-tools .form-body .user-img-upload .avatar-upload-img .avatar div img {
  max-width: 100%;
}
.user-tools .form-body .user-img-upload .avatar-upload-img .delete {
  cursor: pointer;
  color: red;
  font-size: 1.2em;
}
.user-tools .form-body .user-img-upload .avatar-upload-btn {
  display: flex;
  padding: 5px 10px;
  border-radius: 9px;
  cursor: pointer;
  overflow: hidden;
  background: linear-gradient(to bottom, #E5E5E5, #a0bddf 50%, #3c709b 100%);
}
.user-tools .form-body .user-img-upload .avatar-upload-btn .img {
  width: 25%;
}
.user-tools .form-body .user-img-upload .avatar-upload-btn .img img {
  width: 100%;
}
.user-tools .form-body .user-img-upload .avatar-upload-btn label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90%;
}
.user-tools .form-body .user-img-upload .avatar-upload-btn .small {
  font-size: 0.8em;
}
.user-tools .form-body .user-img-upload input {
  display: none;
}
.user-tools .form-footer .link {
  color: blue !important;
  text-decoration: underline !important;
}
.user-tools .form-footer button {
  margin-right: 5px;
  margin-bottom: 5px;
}/*# sourceMappingURL=main.css.map */