* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.footer-warning {
  position: fixed;
  z-index: 101;
  width: 100%;
  bottom: 0;
  text-align: center;
  font-size: 0.9em;
  padding: 3px;
  background-color: #ff6633;
  color: #E5E5E5;
  margin: 0;
}
.footer-warning a {
  color: inherit;
  text-decoration: underline;
}

.separator {
  width: 100%;
  height: 10px;
  margin: 5px 0;
}

.events-header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #3c709b, #164B75 60%);
  width: 100%;
  height: 2.5em;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 5px solid #E5E5E5;
  padding-left: 5px;
}
.events-header .vertical-center {
  display: inline-flex;
  align-items: center;
}
.events-header .header-user-component {
  display: inline-flex;
}
.events-header .header-user-component a {
  display: inline-flex;
}
.events-header .header-user-component .img {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #E5E5E5;
  overflow: hidden;
}
@media (max-width: 768px) {
  .events-header .header-user-component .img {
    width: 35px;
    height: 35px;
  }
}
.events-header .header-user-component .img img {
  display: inline-flex;
  width: auto;
  height: 100%;
}
.events-header .menu-block {
  display: inline-flex;
  justify-content: flex-end;
}
.events-header .header-item {
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  color: #E5E5E5;
}
.events-header .header-item:hover {
  color: white;
}
.events-header .header-item a {
  color: #E5E5E5;
}
.events-header .header-item .icon {
  top: -2px;
  margin-right: 5px;
  font-size: 1.5em;
}
.events-header .header-item .title {
  top: -3px;
}
@media (max-width: 768px) {
  .events-header .header-item .title {
    display: none;
  }
}
.events-header .nav-window {
  z-index: 1001;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 8px;
  background: linear-gradient(to right, #3c709b, #164B75 60%);
  color: #E5E5E5;
  border-radius: 20px;
  border: 2px solid #164B75;
  width: 260px;
  cursor: default;
}
.events-header .nav-window .nav-window-header {
  width: 100%;
  text-align: right;
}
.events-header .nav-window a {
  color: #E5E5E5;
}
.events-header .nav-window a:visited, .events-header .nav-window a:hover {
  color: #E5E5E5;
}
.events-header .nav-window ul {
  padding: 5px !important;
  margin-bottom: 0;
}
.events-header .nav-window ul .item {
  text-align: left;
  width: 100%;
  margin: 10px 0;
  cursor: pointer;
  display: inline-flex;
  color: #E5E5E5;
}
.events-header .nav-window ul .item .icon {
  margin-right: 5px;
  top: -2px;
}

.events-list {
  width: 100%;
  margin-top: 3.5em;
  padding-bottom: 100px;
  font-size: 18px;
}
.events-list .event-item {
  width: 60%;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 0 5px 7px gray;
}
.events-list .event-item.cancelled {
  opacity: 0.5;
}
@media (max-width: 992px) {
  .events-list .event-item {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .events-list .event-item {
    width: 100%;
    border-radius: 0;
  }
}
.events-list .event-item .event-item-header {
  background: linear-gradient(to right, #3c709b, #164B75 60%);
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  color: #E5E5E5;
  font-size: 0.9em;
}
@media (max-width: 768px) {
  .events-list .event-item .event-item-header {
    font-size: 0.8em;
    padding: 5px;
  }
}
.events-list .event-item .event-item-header .event-item-header-description {
  display: inline-block;
  max-width: 75%;
}
@media (max-width: 768px) {
  .events-list .event-item .event-item-header .event-item-header-description {
    max-width: 75%;
  }
}
.events-list .event-item .event-item-header .event-item-header-description .event-item-location-date {
  width: 100%;
  display: flex;
  align-items: center;
}
.events-list .event-item .event-item-header .event-item-header-description .event-item-location-date .event-item-location {
  overflow: hidden;
  height: 1.5em;
  word-break: break-all;
  max-width: 60%;
  margin-right: 10px;
  display: inline-block;
}
.events-list .event-item .event-item-header .event-item-header-description .event-item-location-date .event-item-date {
  display: inline-block;
  overflow: hidden;
  height: 1.5em;
  word-break: break-all;
}
.events-list .event-item .event-item-header .event-item-header-description .event-item-name {
  overflow: hidden;
  word-break: break-all;
  display: flex;
}
.events-list .event-item .event-item-header .event-item-header-description .event-item-name .event-is-author {
  display: inline-block;
  width: 10%;
  margin-right: 10px;
  top: -2px;
  font-size: 0.8em;
}
.events-list .event-item .event-item-header .event-item-header-description .event-item-name .description {
  display: inline-block;
  max-width: 90%;
  word-break: keep-all;
  overflow: hidden;
  max-height: 2.5em;
  line-height: 1.2;
}
.events-list .event-item .event-item-header .event-item-header-description .event-item-name .distance {
  display: inline-block;
  min-width: 3em;
  width: 3em;
  height: 1.6em;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #E5E5E5;
  text-align: center;
}
.events-list .event-item .event-item-header .event-item-header-description .event-hike-type-component {
  display: inline-block;
  font-size: 0.8em;
}
.events-list .event-item .event-item-header .event-item-header-description .event-hike-type-component .icon {
  position: relative;
  top: -6px;
  font-size: 1.5em;
}
.events-list .event-item .event-item-header .event-item-header-description .event-hike-type-component .icon.camp {
  top: 0;
}
.events-list .event-item .event-item-header .event-item-header-author {
  display: inline-flex;
  align-items: center;
  width: 25%;
}
.events-list .event-item .event-item-header .event-item-header-author .user-component {
  display: inline-flex;
  align-items: center;
}
.events-list .event-item .event-item-header .event-item-header-author .user-component .img {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  min-width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background-color: #164B75;
  overflow: hidden;
  text-align: center;
  margin-left: 10px;
}
.events-list .event-item .event-item-header .event-item-header-author .user-component .img img {
  width: 100%;
  height: auto;
}
.events-list .event-item .event-item-header .event-item-header-author .user-component .name {
  margin-left: 5px;
  height: 1.5em;
  overflow: hidden;
  word-break: break-all;
}
@media (max-width: 400px) {
  .events-list .event-item .event-item-header .event-item-header-author .user-component .name {
    display: none;
  }
}
.events-list .event-item .event-item-footer {
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8em;
}
@media (max-width: 768px) {
  .events-list .event-item .event-item-footer {
    font-size: 0.7em;
  }
}
@media (max-width: 400px) {
  .events-list .event-item .event-item-footer {
    font-size: 0.6em;
  }
}
.events-list .event-item .event-item-footer .event-item-footer-like {
  display: flex;
}
.events-list .event-item .event-item-footer .event-item-footer-like .item {
  width: 3em;
  text-align: right;
  color: #164B75;
}
.events-list .event-item .event-item-footer .event-item-footer-like .item .count {
  opacity: 1;
}
.events-list .event-item .event-item-footer .event-item-footer-like .item .icon {
  font-size: 1.3em;
  top: -1px;
  z-index: 99;
  color: #164B75;
  opacity: 0.5;
  margin-right: 5px;
}
.events-list .event-item .event-item-footer .event-item-footer-like .item .icon.like:hover {
  opacity: 1;
}
.events-list .event-item .event-item-footer .event-item-footer-like .item .icon.active, .events-list .event-item .event-item-footer .event-item-footer-like .item .icon.unread {
  color: #164B75;
  opacity: 1;
}
.events-list .event-item .event-item-footer .event-item-footer-like .item .icon.registered {
  opacity: 1;
  color: #164B75;
}
.events-list .event-item .event-item-footer .event-item-footer-status {
  display: inline;
  margin-left: 15px;
}
@media (max-width: 768px) {
  .events-list .event-item .event-item-footer .event-item-footer-status {
    margin: 0;
  }
}
.events-list .event-item .event-item-footer .event-item-footer-status .date-change {
  font-size: 0.9em;
  color: #164B75;
}
.events-list .event-item .event-item-footer .event-item-footer-status .icon {
  top: -2px;
  margin-left: 10px;
}/*# sourceMappingURL=events.css.map */