@import "vars";

.event-form {
  width: 60%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: $background  !important;
  border-radius: $radius;
  overflow: hidden;
  color: $color-dark;
  padding: 0;
  font-size: 0.9em;

  @media (max-width: $tablet) {
    width: 85%;
  }

  @media (max-width: $smartphone) {
    width: 100%;
    font-size: 0.7em;
  }

  .padding {
    padding-left: 2em;
  }

  .header {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 5px;
    background: $color-gradient;
  }

  .user-contact {
    img {
      height: 1em;
      margin-left: 10px;
    }
  }

  .event-form-textarea {
    width: 100%;

    .body {
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 5px;
      background: $color-light;
      border-radius: 5px;
      width: 100%;
      word-wrap: normal;
      line-height: 1.3;
    }
  }

  .remark {
    font-size: 0.8em;
    font-style: italic;
  }

  .google-object {
    overflow: hidden;
    text-align: center;

    button {
      margin-top: 10px;
    }

    div {
      margin: auto;
    }
  }

  .event-form-map {
    cursor: pointer;
    font-size: 0.9em;
    color: $color-dark;
    padding: 5px 10px;
    margin: 5px 0;
    border-radius: $radius;
    width: 17em;
    background: linear-gradient(to bottom,
        $background-main,
        $background 50%,
        $background-dark 100%);

    label {
      width: 100%;
      cursor: pointer;
    }

    input {
      display: none;
    }

    span {
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
      width: 30px;
      height: auto;
      float: right;

      @media(max-width:$smartphone) {
        width: 20px;
      }
    }
  }

  .event-form-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.1em;
    background: $background-dark;
    padding: 10px;

    .distance {
      display: inline-block;
      color: $color-light;
      border: 1px solid $color-light;
      border-radius: 5px;
      padding: 2px 5px;
      height: 1.6em;
    }

    .name {
      overflow: hidden;
      color: $color-light;
      line-height: 1.1;
      max-height: 2em;
      margin: 0 15px;

      @media(max-width: $xxs) {
        margin: 0 10px;
      }
    }

    .close {
      text-align: right;
    }
  }

  .event-edit-body {
    padding: 10px 10px;

    select {
      line-height: 1;
      width: auto;
      max-width: 100%;
      font-size: 0.9em;
      margin-bottom: 5px;
    }

    .main-props {
      min-height: 160px;

      @media (max-width: $smartphone) {
        min-height: 140px;
      }
    }

    .pace-relief {
      display: flex;
      justify-content: space-around;

      .pace-relief-element {
        width: 25%;

        @media(max-width:$smartphone) {
          width: 30%;
        }

        @media(max-width:$xxs) {
          width: 40%;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 5px;

        &.margin {
          margin: 10px 0;
        }
      }
    }

    .event-form-select {
      display: inline-block;
    }

    .event-text-input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid $color-light;
      padding: 0 5px;
      word-wrap: break-word;
      word-break: break-all;
      margin: 0 5px;

      &.number {
        width: 3.2em;
      }

      &.auto-width {
        width: auto;
      }
    }

    .event-relief {
      display: flex;
      align-items: center;

      .event-relief-items {
        margin-right: 10px;
      }
    }

    .distance-asphalt {
      display: inline-block;
      width: 45%;
      text-align: center;
      vertical-align: top;

      @media (max-width: $xxs) {
        width: 90%;
        margin-bottom: 10px;
        text-align: left;
      }

      .asphalt-img {
        text-align: center;
        width: 100%;
      }
    }

    .event-level {
      width: 100%;
      padding: 0 10px;

      .event-level-indicator {
        width: 100%;
        height: 1em;
        background-image: linear-gradient(to right, green, yellow, orange, red);
        border-radius: 10px 0 0 10px;

        .event-level-cover {
          position: absolute;
          background-color: $background;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          top: 0;

          img {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        width: 100%;
        overflow: hidden;
      }
    }

    .event-textarea {
      width: 100%;

      textarea {
        width: 100%;
        height: 3.5em;
        border-radius: 5px;
        border: none;
        font-size: 0.9em;
        padding: 0 5px;
        line-height: 1.3;

        @media (max-width: $smartphone) {
          height: 4.5em;
        }
      }

      .event-textarea-view {
        width: 100%;
        height: 3.5em;
        border-radius: 5px;
        font-size: 0.9em;

        @media (max-width: $smartphone) {
          height: 4.5em;
        }
      }
    }

    .event-date-time {

      .input {
        margin-right: 20px;
      }

      .icon {
        top: -2px;
        margin-right: 5px;
      }
    }

    .event-hike {
      min-height: 50px;
    }

    .event-hike-type-select {
      display: inline-block;
      margin-left: 20px;

      @media (max-width: $smartphone) {
        margin-left: 0;
        margin-top: 5px;
      }

      .event-hike-type-item {
        display: inline-block;
        border-radius: 10px;
        cursor: pointer;
        padding: 3px;
        margin-left: 5px;
        font-size: 0.8em;

        &.active {
          text-decoration: underline;
        }

        .icon {
          margin-left: 5px;
        }
      }
    }
  }

  .event-view-body {
    padding: 10px;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      padding: 0 10px;
      display: flex;
      width: 100%;

      @media (max-width: $smartphone) {
        padding: 0 5px;
      }
    }

    .event-view-comments {
      .event-view-comment {
        .user {
          .img {}

          .name {}
        }

        .message {
          .to-user {}
        }
      }
    }

    .event-view-hike {

      .icon {
        top: -2px;
      }
    }

    .event-view-date-author {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date {
        width: 40%;
      }

      .author {
        max-width: 60%;
      }
    }

    .event-view-difficulty {
      display: flex;
      align-items: center;
      font-size: 0.8em;
    }

    .event-props-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;

      .section {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        font-size: 0.9em;

        @media (max-width: $xxs) {
          text-align: center;
          justify-content: center;
          letter-spacing: -0.05em;
        }
      }
    }

    .event-form-location-view {
      .icon {
        top: -4px;
      }

      .region {
        font-size: 0.7em;
      }
    }

    .event-form-author-view {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;

      .img {
        width: 2.5em;
        min-width: 2.5em;
        height: 2.5em;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .event-form-date-view {

      .icon {
        top: -3px;
      }

      .text {
        margin: 0 10px;
      }
    }
  }

  .event-register-disable {
    background-color: $color-middle;
    border-radius: 9px;
    padding: 4px 10px 5px 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .icon {
      top: -3px;
      margin-right: 5px;
    }
  }

  .event-form-footer {
    padding: 0 10px;

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 5px;

        &.margin {
          margin: 10px 0;
        }
      }
    }
  }

  .event-edit-register {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .event-edit-register-stop {
    display: flex;
    align-items: center;
  }

  .event-buttons-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    bottom: 10px;

    @media(max-width:$smartphone) {
      button {
        font-size: 1em;
      }
    }

    @media(max-width:$xxs) {
      button {
        font-size: 0.9em;
      }
    }

    &.center {
      justify-content: space-around;
      margin-top: 20px;
    }

    button {
      margin-bottom: 5px;

      @media(max-width:$xxs) {
        font-size: 0.9em;
      }
    }
  }

  .event-riders-list {
    width: 75%;
    height: 75%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: $background;
    border-radius: $radius;
    overflow: hidden;
    z-index: 1000;

    @media (max-width: $smartphone) {
      width: 90%;
    }

    .event-riders-list-header {
      background-color: $background-dark;
      text-align: right;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: $color-light;
      font-size: 1.2em;

      .event-riders-list-title {
        display: inline-block;
        width: 90%;
        text-align: center;
      }
    }

    .event-riders-list-body {
      padding: 10px;
      height: 100%;
      overflow: auto;

      .event-riders-list-img {
        display: inline-flex;
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: grey;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        img {
          display: inline-flex;
          width: auto;
          height: 100%;
        }
      }

      .event-riders-list-author {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding-left: 15px;

        span {
          margin-right: 5px;
        }
      }

      .event-riders-list-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-left: 25px;

        span {
          margin-right: 5px;
        }
      }
    }
  }
}