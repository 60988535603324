@import "vars";

.main-page {
  width: 100%;
  header {
    position: sticky;
    width: 100%;
    z-index: 2;
    @media (max-width: $smartphone) {
      padding: 5px;
    }
    .header-content {
      width: 80%;
      margin: auto;
      padding: 10px 0 30px 0;
      text-align: right;
      @media (max-width: $smartphone) {
        width: 90%;
      }
      .main-logo {
        position: absolute;
        width: 200px;
        @media (max-width: $smartphone) {
          width: 150px;
        }
        @media (max-width: $xxs) {
          width: 100px;
        }
        img {
          width: 100%;
        }
      }
      nav {
        display: inline-block;
        width: 70%;
        @media (max-width: $smartphone) {
          width: 60%;
        }
        right: 0;
        text-align: right;
        .buttons {
          display: inline-block;
          .nav-item {
            //buttons
            display: inline-block;
            cursor: pointer;
            font-size: 0.9em;
            color: $color-dark;
            height: 2em;
            padding: 0 5px;
            line-height: 2;
            margin: 5px 0 5px 15px;
            border-radius: 9px;
            width: 6em;
            text-align: center;
            background: linear-gradient(
              to top,
              $background,
              $background 50%,
              $color-light 100%
            );
            @media (max-width: $smartphone) {
              font-size: 0.8em;
            }
          }
        }
        .user-login {
          display: inline-flex;
          vertical-align: middle;
          align-items: center;
          margin-left: 20px;
          color: $link;
          .avatar {
            display: inline-flex;
            width: 2em;
            height: 2em;
            overflow: hidden;
            border-radius: 50%;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            }
            img {
              display: inline-block;
              width: 100%;
              vertical-align: middle;
            }
          }
          .name {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            font-size: 0.8em;
            max-width: 150px;
            height: 1.5em;
            margin-left: 5px;
            @media (max-width: $smartphone) {
              display: none;
            }
          }
          .button {
            display: inline-flex;
            margin-left: 5px;
            cursor: pointer;
            font-size: 2em;
            a {
              color: $link;
            }
          }
        }
      }
    }
  }
  .slide {
    overflow: hidden;
    margin: 50px 0;
    @media (max-width: $smartphone) {
      margin: 20px 0;
    }
    .slide-img {
      width: 80%;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      margin: auto;
      @media (max-width: $smartphone) {
        border-radius: 0;
        width: 100%;
      }
      img {
        width: 100%;
        margin: auto;
      }
    }
    .slide-text {
      position: absolute;
      color: $color_dark;
      background-color: $background-main;
      height: 45%;
      width: 30%;
      border-radius: 16px;
      padding: 15px;
      bottom: -20%;
      right: 15%;
      font-size: 1.2em;
      line-height: 1;
      @media (max-width: $tablet) {
        font-size: 0.9em;
      }
      @media (max-width: $smartphone) {
        font-size: 0.85em;
        width: 45%;
        height: 55%;
        right: 3%;
      }
      @media (max-width: $xxs) {
        font-size: 0.6em;
        width: 45%;
        height: 60%;
        right: 3%;
      }
    }
    &.top {
      .slide-text {
        top: -20%;
        padding-top: 12%;
        @media (max-width: $tablet) {
          padding-top: 12.5%;
        }
        @media (max-width: $smartphone) {
          padding-top: 17%;
        }
      }
    }
    &.left {
      .slide-text {
        left: 15%;
        @media (max-width: $smartphone) {
          left: 5%;
        }
      }
    }
  }
  .intro {
    width: 80%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 30px;
    border-top: 2px solid $color_main;
    line-height: 1.3;
    @media (max-width: $smartphone) {
      flex-direction: column;
      width: 90%;
      margin-top: 15px;
      padding-top: 15px;
    }
    h1 {
      width: 100%;
      color: $color_main;
    }
    div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .text {
      display: inline-block;
      color: $color_main;
      text-align: justify;
      width: 70%;
      @media (max-width: $smartphone) {
        font-size: 0.8em;
        width: 100%;
      }
      @media (max-width: $xxs) {
        text-align: left;
      }
    }
    .img {
      width: 20%;
      text-align: right;
      @media (max-width: $tablet) {
        width: 25%;
      }
      @media (max-width: $smartphone) {
        width: 30%;
        margin-top: 20px;
      }
      @media (max-width: $xxs) {
        width: 40%;
        margin-top: 20px;
      }
      img {
        border-radius: 15px;
        width: 100%;
      }
    }
  }
  .footer {
    text-align: center;
    color: $link;
    margin: 100px 0 20px;
    border-bottom: 2px solid $color_main;
    padding-bottom: 10px;
    margin-bottom: 20px;
    a {
      margin-left: 20px;
    }
    img {
      width: 50px;
    }
  }
}
.user-contacts-component {
  display: flex;
  align-items: center;
  input {
    margin-left: 10px !important;
  }
  img {
    width: 1.2em;
    cursor: pointer;
    margin: 5px;
    opacity: 0.5;
    &.active {
      width: 1.4em;
      border: 2px solid white;
      border-radius: 50%;
      opacity: 1;
    }
  }
}
.change-password-component {
  margin-bottom: 20px;
  input {
    margin: 0 0 5px 0 !important;
  }
}
.user-tools {
  width: 60%;
  background-color: $background-dark;
  border-radius: 9px;
  @media (max-width: 767px) {
    width: 95%;
  }
  margin: auto;
  margin-top: 5%;
  padding: 3%;
  color: $color-light;
  .form-header {
    font-weight: bold;
    h3 {
      font-size: 1.1em;
      font-weight: bold;
    }
  }
  .form-body {
    color: $color-light;
    select {
      width: 80%;
      @media (max-width: $tablet) {
        width: 95%;
      }
      @media (max-width: $smartphone) {
        width: 100%;
      }
      margin-bottom: 10px !important;
    }
    input {
      margin: 10px 0;
      width: 80%;
      &.small {
        width: 50%;
      }
      &.number {
        width: 6em;
      }
      @media (max-width: $tablet) {
        width: 95%;
      }
      @media (max-width: $smartphone) {
        width: 100%;
      }
      padding: 0 0 0 10px;
      border-radius: 3px;
      border: none;
    }
    button {
      padding: 3px;
      margin: 10px;
      border-radius: 3px;
    }
    textarea {
      width: 80%;
      @media (max-width: $tablet) {
        width: 95%;
      }
      @media (max-width: $smartphone) {
        width: 100%;
      }
      border-radius: 7px;
      min-height: 3em;
      padding: 5px;
      margin: 10px 0;
    }
    .user-img-upload {
      margin-bottom: 35px;
      font-size: 1.1em;
      width: 80%;
      @media (max-width: $tablet) {
        width: 95%;
      }
      @media (max-width: $smartphone) {
        width: 100%;
      }
      .avatar-upload-img {
        display: flex;
        align-items: center;
        .avatar {
          display: flex;
          justify-content: center;
          margin-right: 20px;
          overflow: hidden;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          div {
            display: flex;
            align-items: center;
            img {
              max-width: 100%;
            }
          }
        }
        .delete {
          cursor: pointer;
          color: red;
          font-size: 1.2em;
        }
      }
      .avatar-upload-btn {
        display: flex;
        padding: 5px 10px;
        border-radius: 9px;
        cursor: pointer;
        overflow: hidden;
        background: linear-gradient(
          to bottom,
          $background-main,
          $background 50%,
          $background-dark 100%
        );
        .img {
          width: 25%;
          img {
            width: 100%;
          }
        }
        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 90%;
        }
        .small {
          font-size: 0.8em;
        }
      }
      input {
        display: none;
      }
    }
  }
  .form-footer {
    .link {
      color: blue !important;
      text-decoration: underline !important;
    }
    button {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
