$background: #a0bddf;
$background_superdark: #164B75;
$background-dark: #3c709b;
$background_main: #E5E5E5;
$color-text: #140a01;
$radius: 20px;

$background-gradient: linear-gradient(to right,
$background-dark,
$background_superdark 60%,
         );

$color_main: $background_superdark;
$color-light: $background-main;
$color-dark: $background_superdark;
$link: $background_superdark;
$button: $background_superdark;

$color-middle: rgba(255, 255, 255, 0.4);
$background_half: rgba(160, 189, 223, 0.5);
$color-bright: #ff6633;
$color-warning: rgb(236, 138, 26);
$color-ok: green;
$color-error: rgb(185, 18, 6);
$color-gradient: linear-gradient(to right,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.4) 80%,
        rgba(255, 255, 255, 0));

$tablet: 992px;
$smartphone: 768px;
$xxs: 400px;