@import "vars";

@font-face {
  /* logo */
  font-family: "share_techregular";
  src: url("../fonts/sharetech-regular-webfont.woff2") format("woff2"),
    url("../fonts/sharetech-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helvetica_cyregular";
  src: url("../fonts/helvetica_cy-webfont.eot");
  src: url("../fonts/helvetica_cy-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica_cy-webfont.woff2") format("woff2"),
    url("../fonts/helvetica_cy-webfont.woff") format("woff"),
    url("../fonts/helvetica_cy-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helvetica_cybold";
  src: url("../fonts/helvetica_cy_bold-webfont.eot");
  src: url("../fonts/helvetica_cy_bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica_cy_bold-webfont.woff2") format("woff2"),
    url("../fonts/helvetica_cy_bold-webfont.woff") format("woff"),
    url("../fonts/helvetica_cy_bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helvetica_cybold_italic";
  src: url("../fonts/helvetica_cy_bold_italic-webfont.eot");
  src: url("../fonts/helvetica_cy_bold_italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica_cy_bold_italic-webfont.woff2") format("woff2"),
    url("../fonts/helvetica_cy_bold_italic-webfont.woff") format("woff"),
    url("../fonts/helvetica_cy_bold_italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helvetica_cyitalic";
  src: url("../fonts/helvetica_cy_italic-webfont.eot");
  src: url("../fonts/helvetica_cy_italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica_cy_italic-webfont.woff2") format("woff2"),
    url("../fonts/helvetica_cy_italic-webfont.woff") format("woff"),
    url("../fonts/helvetica_cy_italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bamf_italicitalic";
  src: url("../fonts/bamfi-webfont.woff2") format("woff2"),
    url("../fonts/bamfi-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

body {
  position: relative;
  width: 100%;
  min-width: 320px;
  font-family: "helvetica_cyregular";
  font-size: 22px;
  color: $color-dark;
  margin: 0;
  padding: 0;

  #root,
  .app {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    background-color: $background-main;
  }
}

.modal-pad {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: default;
}

.disabled-element {
  cursor: not-allowed !important;
  opacity: 0.5 !important;

  a {
    cursor: not-allowed !important;
    pointer-events: none;
  }
}

.status-message {
  display: inline-block;
  margin-right: 5px;
  line-height: 1em;
  top: -2px;

  &.status-warning {
    color: $color-warning;
  }

  &.status-ok {
    color: $color-ok;
  }

  &.status-info {
    color: $color-ok;
  }

  &.status-error {
    color: $color-error;
  }
}


.status-line {
  display: flex;
  width: 100%;
  margin: 5px 0;
  color: $color-dark;
  border-radius: 3px;
  padding: 0 3px;
  background-color: rgba(255, 255, 255, 0.8);
}

.preloader-line {
  width: 100%;
  position: relative;
}

.preloader-img {
  width: 50px;
  height: 50px;
  display: none;
  position: relative;
  margin: auto;
}

.warning {
  position: relative;
  margin: 100px auto;
  text-align: center;

  h3 {
    margin-bottom: 30px;
  }
}

.click {
  cursor: pointer;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $color-text;

  &:visited {
    color: $color-text;
  }

  &:hover {
    color: $color-text;
  }
}

button {
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
}

.invisible {
  display: none !important;
}

.w100 {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.nav-window-close {
  z-index: 1000;
  cursor: pointer;
  font-size: 1.4em;
  color: $color-light;

  &:hover {
    color: $color-light;
  }
}

.checkbox {
  cursor: pointer;
  display: inline-flex;
  width: 1em;
  height: 1em;
  border-radius: 3px;
  background-color: $color-light;
  margin: auto 10px;
  align-items: center;
}

.logo {
  display: inline-flex;
  align-items: center;

  img {
    height: 1.8em;
  }

  span {
    font-family: bamf_italicitalic;
    font-size: 0.7em;

    @media (max-width: $smartphone) {
      display: none;
    }
  }
}

.event-item-body {
  display: flex;
  width: 100%;
  min-height: 3em;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: $background;
  padding: 10px 10px 5px 10px;
  font-size: 0.9em;

  @media (max-width: $smartphone) {
    padding: 5px;
    font-size: 0.7em;
  }

  @media (max-width: $xxs) {
    padding: 5px;
    font-size: 0.6em;
    letter-spacing: -0.05em;
  }

  .event-item-body-property {
    display: flex;
    width: 25%;
    text-align: center;
    justify-content: center;
    align-items: space-between;
    flex-wrap: wrap;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
      }
    }

    .title {
      width: 100%;
    }
  }
}

// .event-property-component {
//   display: flex;
//   align-items: center;
//   width: 100%;
//   text-align: center;
//   justify-content: center;
//   flex-wrap: wrap;

//   .img {
//     width: 100%;
//     max-width: 40px;

//     @media(max-width: $smartphone) {
//       max-width: 30px;
//     }

//     img {
//       width: 100%;
//     }
//   }

//   .text {
//     width: 100%;
//   }
// }

// .event-road-component {

//   text-align: center;

//   .distance {
//     font-weight: bold;
//   }

//   .icon {
//     top: -3px;
//     margin-right: 5px;
//     display: none;

//     @media(max-width:$xxs) {
//       display: none;
//     }
//   }

//   .text {
//     top: -6px;
//   }

//   .road-img {
//     width: 80px;
//     height: 20px;
//     overflow: hidden;
//     position: relative;
//     margin: auto;

//     @media(max-width: $smartphone) {
//       width: 60px;
//     }

//     img {
//       width: 80px;
//       position: absolute;
//       left: 0;
//       top: 0;

//       @media(max-width: $smartphone) {
//         width: 60px;
//       }
//     }
//   }
// }

.default-radio-group {
  height: 100%;

  .default-radio-group-title {
    font-size: 1em;
  }

  .default-radio-group-body {
    font-size: 0.8em;
    margin-left: 10px !important;

    li {
      cursor: pointer;

      .icon {
        width: 1.2em;
        height: 1.2em;
        display: inline-block;
        position: relative;
        top: -3px;
        margin-right: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        vertical-align: middle;
        top: -2px;
      }

      .no-active {
        div {
          border: 2px solid gray;
        }
      }

      .active {
        span {
          font-weight: bold;
        }
      }
    }
  }
}


.modal-window {
  width: 75%;
  height: 75%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: $background;
  border-radius: $radius;
  overflow: hidden;
  z-index: 1000;

  @media (max-width: $smartphone) {
    width: 90%;
  }

  &.small-window {
    width: 50%;
    height: 50%;

    @media (max-width: $smartphone) {
      width: 90%;
    }

    @media (max-width: $xxs) {
      width: 100%;
      height: 75%;
    }
  }

  &.large-window {
    width: 90%;
    height: 90%;
  }

  .modal-window-header {
    background-color: $background-dark;
    color: $color-light;
    text-align: right;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20%;
    font-size: 1.2em;

    .modal-window-title {
      display: inline-block;
      width: 90%;
      text-align: center;
    }
  }

  .modal-window-body {
    padding: 10px;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      padding: 10px 0;
      margin: 0;

      &.italic {
        font-style: italic;
      }
    }
  }

  .modal-window-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
  }
}

.share-fb-button {
  display: inline-block;
  vertical-align: middle;
  background-color: #1877f2;
  color: white;
  font-size: 0.8em;
  border-radius: 5px;
  margin: 5px 0;
  padding: 0 5px;

  a {
    color: white;
  }

  img {
    height: 1em;
    margin-right: 5px;
  }
}

.copy-link-button {
  display: inline-block;
  vertical-align: middle;
  background-color: $background;
  font-size: 0.8em;
  border-radius: 5px;
  margin: 5px 10px;
  padding: 0 5px;
  cursor: pointer;

  .icon {
    margin: 0 5px;
  }
}