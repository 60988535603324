@import "vars";

$header_height: 2.5em;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.footer-warning {
  position: fixed;
  z-index: 101;
  width: 100%;
  bottom: 0;
  text-align: center;
  font-size: 0.9em;
  padding: 3px;
  background-color: $color-bright;
  color: $color-light;
  margin: 0;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.separator {
  width: 100%;
  height: 10px;
  margin: 5px 0;
}

.events-header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: $background-gradient;
  width: 100%;
  height: $header_height;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 5px solid $color-light;
  padding-left: 5px;

  .vertical-center {
    display: inline-flex;
    align-items: center;
  }

  .header-user-component {
    display: inline-flex;

    a {
      display: inline-flex;
    }

    .img {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $background-main;
      overflow: hidden;

      @media (max-width: $smartphone) {
        width: 35px;
        height: 35px;
      }

      img {
        display: inline-flex;
        width: auto;
        height: 100%;
      }
    }
  }

  .menu-block {
    display: inline-flex;
    justify-content: flex-end;
  }

  .header-item {
    display: inline-flex;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    color: $color-light;

    &:hover {
      color: white;
    }

    a {
      color: $color-light;
    }

    .icon {
      top: -2px;
      margin-right: 5px;
      font-size: 1.5em;
    }

    .title {
      top: -3px;

      @media (max-width: $smartphone) {
        display: none;
      }
    }
  }

  .nav-window {
    z-index: 1001;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 8px;
    background: $background-gradient;
    color: $color-light;
    border-radius: $radius;
    border: 2px solid $color-dark;
    width: 260px;
    cursor: default;

    .nav-window-header {
      width: 100%;
      text-align: right;
    }

    a {
      color: $color-light;

      &:visited,
      &:hover {
        color: $color-light;
      }
    }

    ul {
      padding: 5px !important;
      margin-bottom: 0;

      .item {
        text-align: left;
        width: 100%;
        margin: 10px 0;
        cursor: pointer;
        display: inline-flex;
        color: $color-light;

        .icon {
          margin-right: 5px;
          top: -2px;
        }
      }
    }
  }
}

.events-list {
  width: 100%;
  margin-top: $header_height+1em;
  padding-bottom: 100px;
  font-size: 18px;

  .event-item {
    width: 60%;

    &.cancelled {
      opacity: 0.5;
    }

    @media (max-width: $tablet) {
      width: 90%;
    }

    @media (max-width: $smartphone) {
      width: 100%;
      border-radius: 0;
    }

    margin: auto;
    border-radius: $radius;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 0 5px 7px gray;

    .event-item-header {
      background: $background-gradient;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      color: $color-light;
      font-size: 0.9em;

      @media (max-width: $smartphone) {
        font-size: 0.8em;
        padding: 5px;
      }

      .event-item-header-description {
        display: inline-block;
        max-width: 75%;

        @media(max-width:$smartphone) {
          max-width: 75%;
        }

        .event-item-location-date {
          width: 100%;
          display: flex;
          align-items: center;

          .event-item-location {
            overflow: hidden;
            height: 1.5em;
            word-break: break-all;
            max-width: 60%;
            margin-right: 10px;
            display: inline-block;
          }

          .event-item-date {
            display: inline-block;
            overflow: hidden;
            height: 1.5em;
            word-break: break-all;
          }

        }

        .event-item-name {
          overflow: hidden;
          word-break: break-all;
          display: flex;

          .event-is-author {
            display: inline-block;
            width: 10%;
            margin-right: 10px;
            top: -2px;
            font-size: 0.8em;
          }

          .description {
            display: inline-block;
            max-width: 90%;
            word-break: keep-all;
            overflow: hidden;
            max-height: 2.5em;
            line-height: 1.2;
          }

          .distance {
            display: inline-block;
            min-width: 3em;
            width: 3em;
            height: 1.6em;
            margin-right: 10px;
            border-radius: 5px;
            border: 1px solid $color-light;
            text-align: center;
          }
        }

        .event-hike-type-component {
          display: inline-block;
          font-size: 0.8em;

          .icon {
            position: relative;
            top: -6px;
            font-size: 1.5em;

            &.camp {
              top: 0;
            }
          }
        }
      }

      .event-item-header-author {
        display: inline-flex;
        align-items: center;
        width: 25%;

        .user-component {
          display: inline-flex;
          align-items: center;

          .img {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 2.5em;
            min-width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            background-color: $background_superdark;
            overflow: hidden;
            text-align: center;
            margin-left: 10px;

            img {
              width: 100%;
              height: auto;
            }
          }

          .name {
            margin-left: 5px;
            height: 1.5em;
            overflow: hidden;
            word-break: break-all;

            @media(max-width:$xxs) {
              display: none;
            }
          }
        }

      }
    }

    .event-item-footer {
      width: 100%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8em;

      @media(max-width:$smartphone) {
        font-size: 0.7em;
      }

      @media(max-width:$xxs) {
        font-size: 0.6em;
      }

      .event-item-footer-like {
        display: flex;

        .item {
          width: 3em;
          text-align: right;
          color: $color-dark;

          .count {
            opacity: 1;
          }

          .icon {
            font-size: 1.3em;
            top: -1px;
            z-index: 99;
            color: $color-dark;
            opacity: .5;
            margin-right: 5px;

            &.like {
              &:hover {
                opacity: 1;
              }
            }

            &.active,
            &.unread {
              color: $color-dark;
              opacity: 1;
            }

            &.registered {
              opacity: 1;
              color: $color-dark;
            }
          }
        }
      }

      .event-item-footer-status {
        display: inline;
        margin-left: 15px;

        @media(max-width:$smartphone) {
          margin: 0;
        }

        .date-change {
          font-size: 0.9em;
          color: $color-dark;
        }

        .icon {
          top: -2px;
          margin-left: 10px;
        }
      }
    }
  }
}