.event-form {
  width: 60%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #a0bddf !important;
  border-radius: 20px;
  overflow: hidden;
  color: #164B75;
  padding: 0;
  font-size: 0.9em;
}
@media (max-width: 992px) {
  .event-form {
    width: 85%;
  }
}
@media (max-width: 768px) {
  .event-form {
    width: 100%;
    font-size: 0.7em;
  }
}
.event-form .padding {
  padding-left: 2em;
}
.event-form .header {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 5px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 80%, rgba(255, 255, 255, 0));
}
.event-form .user-contact img {
  height: 1em;
  margin-left: 10px;
}
.event-form .event-form-textarea {
  width: 100%;
}
.event-form .event-form-textarea .body {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 5px;
  background: #E5E5E5;
  border-radius: 5px;
  width: 100%;
  word-wrap: normal;
  line-height: 1.3;
}
.event-form .remark {
  font-size: 0.8em;
  font-style: italic;
}
.event-form .google-object {
  overflow: hidden;
  text-align: center;
}
.event-form .google-object button {
  margin-top: 10px;
}
.event-form .google-object div {
  margin: auto;
}
.event-form .event-form-map {
  cursor: pointer;
  font-size: 0.9em;
  color: #164B75;
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 20px;
  width: 17em;
  background: linear-gradient(to bottom, #E5E5E5, #a0bddf 50%, #3c709b 100%);
}
.event-form .event-form-map label {
  width: 100%;
  cursor: pointer;
}
.event-form .event-form-map input {
  display: none;
}
.event-form .event-form-map span {
  vertical-align: middle;
}
.event-form .event-form-map img {
  vertical-align: middle;
  width: 30px;
  height: auto;
  float: right;
}
@media (max-width: 768px) {
  .event-form .event-form-map img {
    width: 20px;
  }
}
.event-form .event-form-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.1em;
  background: #3c709b;
  padding: 10px;
}
.event-form .event-form-header .distance {
  display: inline-block;
  color: #E5E5E5;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  padding: 2px 5px;
  height: 1.6em;
}
.event-form .event-form-header .name {
  overflow: hidden;
  color: #E5E5E5;
  line-height: 1.1;
  max-height: 2em;
  margin: 0 15px;
}
@media (max-width: 400px) {
  .event-form .event-form-header .name {
    margin: 0 10px;
  }
}
.event-form .event-form-header .close {
  text-align: right;
}
.event-form .event-edit-body {
  padding: 10px 10px;
}
.event-form .event-edit-body select {
  line-height: 1;
  width: auto;
  max-width: 100%;
  font-size: 0.9em;
  margin-bottom: 5px;
}
.event-form .event-edit-body .main-props {
  min-height: 160px;
}
@media (max-width: 768px) {
  .event-form .event-edit-body .main-props {
    min-height: 140px;
  }
}
.event-form .event-edit-body .pace-relief {
  display: flex;
  justify-content: space-around;
}
.event-form .event-edit-body .pace-relief .pace-relief-element {
  width: 25%;
}
@media (max-width: 768px) {
  .event-form .event-edit-body .pace-relief .pace-relief-element {
    width: 30%;
  }
}
@media (max-width: 400px) {
  .event-form .event-edit-body .pace-relief .pace-relief-element {
    width: 40%;
  }
}
.event-form .event-edit-body ul {
  margin: 0;
  padding: 0;
}
.event-form .event-edit-body ul li {
  margin-bottom: 5px;
}
.event-form .event-edit-body ul li.margin {
  margin: 10px 0;
}
.event-form .event-edit-body .event-form-select {
  display: inline-block;
}
.event-form .event-edit-body .event-text-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #E5E5E5;
  padding: 0 5px;
  word-wrap: break-word;
  word-break: break-all;
  margin: 0 5px;
}
.event-form .event-edit-body .event-text-input.number {
  width: 3.2em;
}
.event-form .event-edit-body .event-text-input.auto-width {
  width: auto;
}
.event-form .event-edit-body .event-relief {
  display: flex;
  align-items: center;
}
.event-form .event-edit-body .event-relief .event-relief-items {
  margin-right: 10px;
}
.event-form .event-edit-body .distance-asphalt {
  display: inline-block;
  width: 45%;
  text-align: center;
  vertical-align: top;
}
@media (max-width: 400px) {
  .event-form .event-edit-body .distance-asphalt {
    width: 90%;
    margin-bottom: 10px;
    text-align: left;
  }
}
.event-form .event-edit-body .distance-asphalt .asphalt-img {
  text-align: center;
  width: 100%;
}
.event-form .event-edit-body .event-level {
  width: 100%;
  padding: 0 10px;
}
.event-form .event-edit-body .event-level .event-level-indicator {
  width: 100%;
  height: 1em;
  background-image: linear-gradient(to right, green, yellow, orange, red);
  border-radius: 10px 0 0 10px;
  width: 100%;
  overflow: hidden;
}
.event-form .event-edit-body .event-level .event-level-indicator .event-level-cover {
  position: absolute;
  background-color: #a0bddf;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  top: 0;
}
.event-form .event-edit-body .event-level .event-level-indicator .event-level-cover img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.event-form .event-edit-body .event-textarea {
  width: 100%;
}
.event-form .event-edit-body .event-textarea textarea {
  width: 100%;
  height: 3.5em;
  border-radius: 5px;
  border: none;
  font-size: 0.9em;
  padding: 0 5px;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .event-form .event-edit-body .event-textarea textarea {
    height: 4.5em;
  }
}
.event-form .event-edit-body .event-textarea .event-textarea-view {
  width: 100%;
  height: 3.5em;
  border-radius: 5px;
  font-size: 0.9em;
}
@media (max-width: 768px) {
  .event-form .event-edit-body .event-textarea .event-textarea-view {
    height: 4.5em;
  }
}
.event-form .event-edit-body .event-date-time .input {
  margin-right: 20px;
}
.event-form .event-edit-body .event-date-time .icon {
  top: -2px;
  margin-right: 5px;
}
.event-form .event-edit-body .event-hike {
  min-height: 50px;
}
.event-form .event-edit-body .event-hike-type-select {
  display: inline-block;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .event-form .event-edit-body .event-hike-type-select {
    margin-left: 0;
    margin-top: 5px;
  }
}
.event-form .event-edit-body .event-hike-type-select .event-hike-type-item {
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  padding: 3px;
  margin-left: 5px;
  font-size: 0.8em;
}
.event-form .event-edit-body .event-hike-type-select .event-hike-type-item.active {
  text-decoration: underline;
}
.event-form .event-edit-body .event-hike-type-select .event-hike-type-item .icon {
  margin-left: 5px;
}
.event-form .event-view-body {
  padding: 10px;
}
.event-form .event-view-body ul {
  margin: 0;
  padding: 0;
}
.event-form .event-view-body li {
  padding: 0 10px;
  display: flex;
  width: 100%;
}
@media (max-width: 768px) {
  .event-form .event-view-body li {
    padding: 0 5px;
  }
}
.event-form .event-view-body .event-view-hike .icon {
  top: -2px;
}
.event-form .event-view-body .event-view-date-author {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.event-form .event-view-body .event-view-date-author .date {
  width: 40%;
}
.event-form .event-view-body .event-view-date-author .author {
  max-width: 60%;
}
.event-form .event-view-body .event-view-difficulty {
  display: flex;
  align-items: center;
  font-size: 0.8em;
}
.event-form .event-view-body .event-props-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.event-form .event-view-body .event-props-block .section {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  font-size: 0.9em;
}
@media (max-width: 400px) {
  .event-form .event-view-body .event-props-block .section {
    text-align: center;
    justify-content: center;
    letter-spacing: -0.05em;
  }
}
.event-form .event-view-body .event-form-location-view .icon {
  top: -4px;
}
.event-form .event-view-body .event-form-location-view .region {
  font-size: 0.7em;
}
.event-form .event-view-body .event-form-author-view {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.event-form .event-view-body .event-form-author-view .img {
  width: 2.5em;
  min-width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 10px;
}
.event-form .event-view-body .event-form-author-view .img img {
  width: 100%;
  height: 100%;
}
.event-form .event-view-body .event-form-date-view .icon {
  top: -3px;
}
.event-form .event-view-body .event-form-date-view .text {
  margin: 0 10px;
}
.event-form .event-register-disable {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 9px;
  padding: 4px 10px 5px 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.event-form .event-register-disable .icon {
  top: -3px;
  margin-right: 5px;
}
.event-form .event-form-footer {
  padding: 0 10px;
}
.event-form .event-form-footer ul {
  margin: 0;
  padding: 0;
}
.event-form .event-form-footer ul li {
  margin-bottom: 5px;
}
.event-form .event-form-footer ul li.margin {
  margin: 10px 0;
}
.event-form .event-edit-register {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.event-form .event-edit-register-stop {
  display: flex;
  align-items: center;
}
.event-form .event-buttons-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  bottom: 10px;
}
@media (max-width: 768px) {
  .event-form .event-buttons-bar button {
    font-size: 1em;
  }
}
@media (max-width: 400px) {
  .event-form .event-buttons-bar button {
    font-size: 0.9em;
  }
}
.event-form .event-buttons-bar.center {
  justify-content: space-around;
  margin-top: 20px;
}
.event-form .event-buttons-bar button {
  margin-bottom: 5px;
}
@media (max-width: 400px) {
  .event-form .event-buttons-bar button {
    font-size: 0.9em;
  }
}
.event-form .event-riders-list {
  width: 75%;
  height: 75%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #a0bddf;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1000;
}
@media (max-width: 768px) {
  .event-form .event-riders-list {
    width: 90%;
  }
}
.event-form .event-riders-list .event-riders-list-header {
  background-color: #3c709b;
  text-align: right;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #E5E5E5;
  font-size: 1.2em;
}
.event-form .event-riders-list .event-riders-list-header .event-riders-list-title {
  display: inline-block;
  width: 90%;
  text-align: center;
}
.event-form .event-riders-list .event-riders-list-body {
  padding: 10px;
  height: 100%;
  overflow: auto;
}
.event-form .event-riders-list .event-riders-list-body .event-riders-list-img {
  display: inline-flex;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: grey;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.event-form .event-riders-list .event-riders-list-body .event-riders-list-img img {
  display: inline-flex;
  width: auto;
  height: 100%;
}
.event-form .event-riders-list .event-riders-list-body .event-riders-list-author {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 15px;
}
.event-form .event-riders-list .event-riders-list-body .event-riders-list-author span {
  margin-right: 5px;
}
.event-form .event-riders-list .event-riders-list-body .event-riders-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 25px;
}
.event-form .event-riders-list .event-riders-list-body .event-riders-list-item span {
  margin-right: 5px;
}/*# sourceMappingURL=event_form.css.map */